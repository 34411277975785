var SITE_URL = window.location.origin;
$(document).on('ready', function () {

	//Contact form validation
    $('#contact-form').validate({
        rules: {
            name: {
                  required: true, 
                  maxlength:100
            },
            email: {
                required: true,
                email: true
            },
            phone: {
                maxlength:25
            },
            message: {
                required: true,
            },
        },
        errorPlacement: function(error,element) {
            return true;
        },
        highlight: function(element) {
            $(element).parent('div').addClass('error');
        },
        unhighlight: function(element) {
            $(element).parent('div').removeClass('error');
        },
        onfocusout: function(element)
        {         
          	/*tmpval = $(element).val().trim();
           	if (tmpval == '') {            
            	$(element).siblings('.formlabel').removeClass('labelup');
            } else {
            	$(element).siblings('.formlabel').addClass('labelup');
            }
            return true;*/
        }
    });
});

// side bar products 
function openNav() {
  document.getElementById("mySidebar").style.width = "95%";
  //document.getElementById("main").style.marginLeft = "290px";
}

function closeNav() {
  document.getElementById("mySidebar").style.width = "0";
  //document.getElementById("main").style.marginLeft= "0";
}